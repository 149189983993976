<template>
  <div class="w-screen pt-16 sm:pt-[105px] h-screen flex flex-col items-center login-page-background px-4 text-white">
    <picture>
      <img src="~/assets/v-dark-logo.svg" alt="Logo">
    </picture>
    <div
      class="max-w-[37.5rem] w-full rounded-3xl flex flex-col mt-16 md:mt-[117px] py-6 sm:py-[46px] px-4 sm:px-[83px] text-center items-center backdrop-blur-sm bg-white/20 drop-shadow-login-window"
    >
      <NuxtPage/>
    </div>
    <div class="w-full md:w-9/12 lg:w-7/12 xl:w-6/12 2xl:w-4/12">
      <div class="flex w-full bg-[#D9D9D9] bg-opacity-30 h-1 rounded mt-8"
           :class="{'justify-end' : isLoginOnCounterparty}">
        <div class="w-1/2 bg-white h-1 rounded"></div>
      </div>
      <div class="flex w-full mt-3">
        <div class="flex w-1/2" :class="{'text-[#D9D9D9] text-opacity-30' : isLoginOnCounterparty}">
          <span class="text-4xl">1.</span>
          <p class="flex ml-4 items-center">Авторизация</p>
        </div>
        <div class="flex w-1/2" :class="{'text-[#D9D9D9] text-opacity-30' : !isLoginOnCounterparty}">
          <span class="text-4xl">2.</span>
          <p class="flex ml-4 items-center">Контрагент и договор</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const route = useRoute()
const loginOnCounterparty = computed(() => route.path.toString())

const isLoginOnCounterparty = ref(false)

watch(loginOnCounterparty, () => {
  isLoginOnCounterparty.value = loginOnCounterparty.value !== '/auth/login'
}, { immediate: true })
</script>
